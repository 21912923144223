import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams, Link, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import LinearProgress from "@material-ui/core/LinearProgress";

import ContentTile from "./ContentTile";

const getTagForSlug = gql`
query getTagForSlug($slug_name: ID!, $slug_sequence: Int!) {
  getSlug (slug_name: $slug_name, slug_sequence: $slug_sequence) {
      tag {
        title
        taggings {
            items {
                content {
                    id
                    title
                    slug
                    cover {
                        photo {
                            attachment_uid
                        }
                    }
                }
            }
        }
      }
  } 
}
`;

type Tagging = {
    content: Content
}

type Content = {
    id: string;
    title: string;
    slug: string;
    cover: {
        photo: {
            attachment_uid: string
        }
    }
}

const Tag: React.FC = () => {
    const { id } = useParams<{ id: string; }>();
    const idSplit = id.split("--");

    const { loading, error, data } = useQuery(getTagForSlug, {
        variables: { slug_name: `ActsAsTaggableOn::Tag#${idSplit[0]}`, slug_sequence: idSplit[1] || 1 }
    });

    if (loading) return (
        <div style={{ padding: "1rem" }}><LinearProgress color="secondary" /></div>
    );

    if (error) return <Typography>Error loading data</Typography>;

    const tag = data?.getSlug?.tag

    if (!tag) return (
        <div>
            <Redirect to="/404" />
        </div>
    )

    const canonicalUrl = `https://www.weknowwhatyoudidlastnight.com/tags/${tag.slug}`
    const tagTitle = `Tag ${tag.title}`
    const contents: Content[] = tag?.taggings?.items?.map((t: Tagging) => (t.content)).filter((t: Tagging) => t)

    return (

        <div style={{ padding: "1rem" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{tagTitle}</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content={tagTitle}></meta>
                <meta property="og:url" content={canonicalUrl}></meta>
            </Helmet>
            
            <Typography variant="h4" component="h1" style={{ marginBottom: "1rem" }}>{tagTitle}</Typography>

            {(contents?.length) ? (
                <Grid container spacing={5}>
                    {contents.map((content) => (
                        <Grid key={content.slug} item
                            xs={12} sm={6} md={4} lg={4} xl={3}>
                            <ContentTile content={content} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Typography color="textPrimary" style={{ marginTop: "2rem" }}>
                            No content was found for this tag, it may have been removed. Click here to return to the home page.
                </Typography>
                    </Link>
                )}
        </div>
    )
}

export default Tag;