import React from "react";
import { gql, useQuery } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Helmet } from "react-helmet";

import ContentTile from "./ContentTile";
import { Content } from "./types";

const listContentsForType = gql`
query listContentsForType {
    listContentsForType (content_type: "Gallery") {
        items {
            id
            title
            slug
            cover {
                photo {
                    attachment_uid
                }
            }
            published_at
        }
        nextToken
    } 
}
`;

const Contents: React.FC = () => {
    const { loading, error, data } = useQuery(listContentsForType);

    if (loading) return (
        <div style={{ padding: "1rem" }}><LinearProgress color="secondary" /></div>
    );

    if (error) return <Typography>Error loading data</Typography>;

    const contents = data?.listContentsForType?.items as Content[]

    if (!contents) return <Typography>No content</Typography>

    const canonicalUrl = "https://www.weknowwhatyoudidlastnight.com";

    return (
        <div style={{ padding: "1rem" }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>WeKnowWhatYouDidLastNight</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content="WeKnowWhatYouDidLastNight"></meta>
                <meta property="og:url" content={canonicalUrl}></meta>
            </Helmet>
            <Grid container spacing={2}>
                {contents.map((content) => (
                    <Grid key={content.slug} item
                        xs={6} sm={4} md={3} lg={2} xl={1}>
                        <ContentTile content={content} height={150} width={250} />
                    </Grid>
                ))}
            </Grid>
        </div>
    );
}

export default Contents;
