import React from "react";
import moment from "moment";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import LazyLoad from "react-lazyload";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";

import { Content } from "./types"; 

const ContentTile: React.FC<{content: Content; width?: number; height?: number}> = (props) => {
    const {content, height, width} = props;
    const pubDate = moment(content.published_at).format("DD/MM/YY")

    return (
        <Card style={{maxWidth: width || 500}}>
            <CardActionArea>
                <Link to={`/contents/${content.slug}`} style={{ textDecoration: "none" }}>
                    <LazyLoad offset={500}>
                        <CardMedia
                            style={{height: height || 300}}
                            image={`https://c10j3kawxi.execute-api.eu-west-1.amazonaws.com/${content.cover?.photo?.attachment_uid}?width=300&height=300`}
                            title={content.title}
                        />
                    </LazyLoad>
                    <CardContent style={{padding: "0.25rem"}}>
                        <Typography color="textPrimary" gutterBottom variant="subtitle1">
                            {content.title}
                        </Typography>
                        <Typography color="textSecondary" gutterBottom variant="subtitle1">
                            {pubDate}
                        </Typography>
                    </CardContent>
                </Link>
            </CardActionArea>
        </Card>
    )
}

export default ContentTile;