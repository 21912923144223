import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link
} from "react-router-dom";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import { Helmet } from "react-helmet";
import { Typography } from "@material-ui/core";

import Content from "./Content";
import Contents from "./Contents";
import Photo from "./Photo";
import Tag from "./Tag";

const client = new ApolloClient({
  link: createHttpLink({
    uri: "https://hee6pmdwsvfjhku7e74uc66pim.appsync-api.eu-west-1.amazonaws.com/graphql",
    headers: {
      "x-api-key": "da2-huw7bdyowbabtnktr2l2rvxviq"
    }
  }),
  cache: new InMemoryCache()
});

export default function App() {
  return (
    <Router>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>WeKnowWhatYouDidLastNight</title>
        </Helmet>
        <HideOnScroll>
          <AppBar position="static" style={{ background: "#000000" }}>
            <Toolbar>
              <Link to="/">
                <img src="/logo.jpg" alt="weknowwhatyoudidlastnight logo" height={40} />
              </Link>
            </Toolbar>
          </AppBar>
        </HideOnScroll>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <ApolloProvider client={client}>
          <Switch>
            <Route exact path="/contents">
              <Contents />
            </Route>
            <Route exact path="/contents/:id">
              <Content />
            </Route>
            <Route exact path="/contents/:id/photos/:photoId">
              <Content />
            </Route>
            <Route exact path="/exhibitions/show/:id">
              <Content />
            </Route>
            <Route exact path="/exhibitions/:id">
              <Content />
            </Route>
            <Route exact path="/exhibitions/:contentId/photos/:id">
              <Photo />
            </Route>
            <Route exact path="/photos/:id">
              <Photo />
            </Route>
            <Route exact path="/photos/big/:id">
              <Photo />
            </Route>
            <Route exact path="/contents/pages/:id">
              <Redirect to="/" />
            </Route>
            <Route exact path="/feeds/christopher-james">
              <Redirect to="/" />
            </Route>
            <Route exact path="/feeds/:feed/contents/pages/:id">
              <Redirect to="/" />
            </Route>
            <Route exact path="/tags/:id">
              <Tag />
            </Route>
            <Route exact path="/">
              <Contents />
            </Route>
            <Route exact path="/404" component={NoMatch} />
            <Route component={NoMatch} />
          </Switch>
        </ApolloProvider>
      </div>
    </Router>
  );
}

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NoMatch() {
  return (
    <div style={{ padding: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not found</title>
      </Helmet>
      <Typography variant="h4">404 - Not found</Typography>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Typography color="textPrimary" style={{ marginTop: "2rem" }}>
          The link you followed points to a page that does not exist. The content you are looking for may have been removed or moved to a different URL. Click here to return to the home page.
        </Typography>
      </Link>
    </div>
  )
}
