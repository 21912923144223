import React, { useEffect, useState } from 'react';
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Masonry from "react-masonry-css"
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import ContentTile from "./ContentTile"
import { Content, ContentPhoto, Photo } from "./types";

const PhotoGallery: React.FC<PhotoGalleryProps> = (props) => {
    const { photos, similarContent, startId, onSlideChange } = props;
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const open = selectedIndex > -1

    let galleryPhotos: ReactImageGalleryItem[] = [];

    const recsTile = {
        original: "",
        renderItem: () => {
            return (
                <div style={{ height: "600px", overflow: "scroll" }}>
                    <Typography variant="h4" style={{ marginBottom: "1rem" }}>similar galleries...</Typography>
                    <Grid container spacing={2}>
                        {similarContent?.length && similarContent.slice(0, 12).map((content) => (
                            <Grid key={content.slug} item
                                xs={6} sm={6} md={2} lg={2} xl={1}>
                                <ContentTile content={content} height={150} width={250} />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            )
        },
        renderThumbInner: () => {
            return (
                <Typography variant="h6" color="textPrimary">more galleries...</Typography>
            )
        }
    }

    if (photos) {
        const filteredPhotos = photos.map(p => p.photo).filter((p): p is Photo => p !== null );
        const sortedPhotos = filteredPhotos.sort((a, b) =>  {
            return (b.published_at === null) ? -1 :
            (a.published_at === null) ? 1 :
            new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
            }
        );
        const newPhotos = sortedPhotos.map(p => ({
            original: `https://images.weknowwhatyoudidlastnight.com/${p.attachment_uid}`,
            thumbnail: `https://c10j3kawxi.execute-api.eu-west-1.amazonaws.com/${p.attachment_uid}?width=300&height=300`
        }))

        galleryPhotos = [...newPhotos, recsTile];
    }

    useEffect(() => {
        // Jump to selected
        if (startId !== null) {
            const index = photos.findIndex(p => p.photo?.slug && p.photo?.slug === startId)
            if (index > -1)
                setSelectedIndex(index);
        }
    }, [photos, startId])

    return (
        <>
            <Masonry
                breakpointCols={1}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {galleryPhotos.filter(p => p.original?.length).map((image, idx) => (
                    <img src={image.thumbnail} key={image.thumbnail} alt="" onClick={() => { setSelectedIndex(idx) }} style={{ cursor: "default", margin: "1px", height: "100px" }} />
                ))}
            </Masonry>
            <Dialog fullScreen open={open} onClose={() => { setSelectedIndex(-1) }}>
                <AppBar color="inherit" style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => { setSelectedIndex(-1) }} aria-label="back">
                            <ArrowBackIos />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <ImageGallery items={galleryPhotos} startIndex={selectedIndex} onSlide={onSlideChange} />
            </Dialog>
        </>

    )
}

type PhotoGalleryProps = {
    photos: ContentPhoto[];
    similarContent?: Content[];
    startId?: string;
    onSlideChange: (index: number) => void;
}

export default PhotoGallery;
