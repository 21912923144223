import React from "react";
import { Link, useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { useParams, Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import { Helmet } from "react-helmet";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Chip from '@material-ui/core/Chip';

import "./image-gallery.css";
import { Content, ContentPhoto, Tagging, Tag } from "./types";
import ContentTile from "./ContentTile";
import PhotoGallery from './PhotoGallery';

const getContentForSlug = gql`
query getContentForSlug($slug_name: ID!, $slug_sequence: Int!) {
  getSlug (slug_name: $slug_name, slug_sequence: $slug_sequence) {
      content {
        id
        title
        content_photos {
          items {
            photo_id
            photo {
              id
              slug
              attachment_uid
              published_at
            }
          }
          nextToken
        }
        cover {
          photo {
              attachment_uid
          }
        }
        published_at
        slug
        taggings {
          items {
            tag {
              title
              slug
              taggings {
                items {
                  content {
                    id
                    title
                    slug
                    cover {
                        photo {
                            attachment_uid
                        }
                    }
                  }
                }
              }
            }
          }
        }
      }
  } 
}
`;

const ContentPage: React.FC = () => {
  const { id, photoId } = useParams<{ id: string; photoId?: string }>();

  const history = useHistory();

  const idSplit = id.split("--");

  const { loading, error, data } = useQuery(getContentForSlug, {
    variables: { slug_name: `Content#${idSplit[0]}`, slug_sequence: idSplit[1] || 1 },
  });

  if (loading) return (
    <div style={{ padding: "1rem" }}><LinearProgress color="secondary" /></div>
  );

  if (error) return <Typography>Error loading data</Typography>;

  const content = data?.getSlug?.content

  if (!content) return (
    <div>
      <Redirect to="/404" />
    </div>
  )

  let similarContent: Content[] = [];
  let taggings: Tagging[] = [];

  if (content?.taggings?.items) {
    taggings = content?.taggings?.items;
    const tags = taggings.map((t: Tagging) => (t.tag))
    similarContent = tags.reduce((acc: Content[], t: Tag) => {
      const taggingsContents: Content[] = t.taggings?.items?.length ?
        t.taggings?.items?.map((tagging: Tagging) => (tagging.content)).filter(c => c) : [];
      return [...acc,
      ...taggingsContents.filter(c => acc.indexOf(c) < 0 && c.id !== content.id)
      ]
    }, [])
  }

  const sourcePhotos: (ContentPhoto[] | null) = data?.getSlug?.content?.content_photos?.items

  const sortedPhotos = sourcePhotos?.slice().sort((a, b) => {
    if (b.photo && a.photo?.published_at && b.photo?.published_at) return new Date(a.photo?.published_at).getSeconds() - new Date(b.photo?.published_at).getSeconds();
    return -1
  });

  const selectedPhoto = photoId && sortedPhotos?.find(p => p.photo?.slug === photoId)

  const canonicalUrl = selectedPhoto ? `https://www.weknowwhatyoudidlastnight.com/contents/${content.slug}/photos/${selectedPhoto.photo?.slug}` : `https://www.weknowwhatyoudidlastnight.com/contents/${content.slug}`
  const pubDate = moment(content.published_at).format("DD/MM/YY")
  const contentTitle = selectedPhoto ? `${content.title} - ${pubDate} - ${selectedPhoto.photo?.slug}` : `${content.title} - ${pubDate}`
  const metaImageId = selectedPhoto ? selectedPhoto.photo?.attachment_uid : content.cover?.photo?.attachment_uid
  const metaImage = `https://images.weknowwhatyoudidlastnight.com/${metaImageId}`

  const handleSlideChange = (index: number): void => {
    const slug = sortedPhotos && sortedPhotos[index].photo?.slug;
    slug && history.push(`/contents/${id}/photos/${slug}`)
  }

  return (
    <div style={{ padding: "1rem" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentTitle}</title>
        <link rel="canonical" href={canonicalUrl} />
        <meta property="og:title" content={contentTitle}></meta>
        <meta property="og:image" content={metaImage}></meta>
        <meta property="og:url" content={canonicalUrl}></meta>
      </Helmet>

      <Link to="/" style={{ textDecoration: "none" }}>
        <Typography variant="h6" color="textPrimary" style={{ verticalAlign: "middle", display: "inline-flex" }}>
          <ArrowBackIos />Galleries</Typography>
      </Link>

      <Typography variant="h4" component="h1" style={{ marginBottom: "1rem", marginTop: "1rem" }}>{content.title} - {pubDate}</Typography>

      {taggings.length && (
        <React.Fragment>
          <p>{taggings.length && taggings.map((tagging) => (
            <Chip key={tagging.tag.slug} label={tagging.tag.title} component="a" href={`/tags/${tagging.tag.slug}`} clickable />
          ))}</p>
        </React.Fragment>
      )}

      {sortedPhotos && (<PhotoGallery photos={sortedPhotos} startId={photoId} onSlideChange={handleSlideChange} />)}

      {similarContent.length && (
        <React.Fragment>
          <Typography variant="h6" style={{ marginBottom: "1rem", marginTop: "2rem" }}>similar galleries...</Typography>
          <Grid container spacing={2}>
            {similarContent.length && similarContent.slice(0, 12).map((content) => (
              <Grid key={content.slug} item
                xs={6} sm={6} md={2} lg={2} xl={1}>
                <ContentTile content={content} height={150} width={250} />
              </Grid>
            ))}
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}

export default ContentPage;
